//
// apexcharts.scss
//

.apex-charts {
    min-height: auto !important;

    text {
        font-family: $font-family-base !important;
    }

    .apexcharts-canvas {
        margin: 0 auto;
    }
}

.apexcharts-canvas {

    ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, .5);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }

    :is(.apexcharts-reset-zoom-icon, .apexcharts-selection-icon, .apexcharts-zoom-icon) {
        &.apexcharts-selected {
            svg {
                fill: var(--#{$prefix}primary);
            }
        }
    }

    @media screen and (min-width:768px) {
        &:hover {
            .apexcharts-toolbar {
                opacity: 1
            }
        }
    }
}

.apexcharts-gridline {
    stroke: var(--#{$prefix}light);
}

.apexcharts-yaxis,
.apexcharts-xaxis {
    text {
        font-family: $font-family-base !important;
        fill: #adb5bd;
    }
}

.apexcharts-title-text,
.apexcharts-subtitle-text {
    fill: var(--#{$prefix}body-color);
}


.apexcharts-legend-text {
    color: var(--#{$prefix}secondary-color) !important;
    font-family: $font-family-base !important;
    font-size: 13px !important;
}


.apexcharts-xaxis-tick {
    stroke: var(--#{$prefix}border-color);
}

.apexcharts-marker {
    stroke: var(--#{$prefix}secondary-bg);
}

.apexcharts-tooltip {
    box-shadow: var(--#{$prefix}box-shadow-lg) !important;

    &.apexcharts-theme-light {
        border: 1px solid var(--#{$prefix}border-color) !important;
        background: rgba(var(--#{$prefix}secondary-bg-rgb), .96) !important;

        .apexcharts-tooltip-title {
            background: var(--#{$prefix}secondary-bg) !important;
            border-bottom: 1px solid var(--#{$prefix}border-color) !important;
        }
    }
}

.apexcharts-tooltip-title {
    font-family: $font-family-base !important;
}

// .apexcharts-treemap,
.apexcharts-heatmap-series, .apexcharts-treemap-series {
    rect {
        stroke: var(--#{$prefix}secondary-bg);
    }
}

.apexcharts-pie-series,
.apexcharts-bar-series {
    path {
        stroke: var(--#{$prefix}secondary-bg);
    }
}

.apexcharts-boxPlot-series {
    path {
        stroke: var(--#{$prefix}border-color);
    }
}

.apexcharts-radialbar {
    .apexcharts-datalabels-group text {
        fill: var(--#{$prefix}body-color);
    }
}

.apexcharts-radialbar-track {
    path {
        stroke: var(--#{$prefix}light);
    }
}

// // radar chart

.apexcharts-radar-series {

    polygon,
    line {
        stroke: var(--#{$prefix}border-color);
    }
}

.apexcharts-pie {

    circle,
    line {
        stroke: var(--#{$prefix}border-color);
    }

    text {
        fill: var(--#{$prefix}white);
    }
}

.apexcharts-xaxistooltip {
    &.apexcharts-theme-light {
        box-shadow: var(--#{$prefix}box-shadow);
        color: var(--#{$prefix}secondary-color);
        background: var(--#{$prefix}secondary-bg);
        border: 1px solid var(--#{$prefix}border-color);
        font-family: $font-family-base;

        &::before {
            border-bottom-color: var(--#{$prefix}border-color);
        }
    }
}

.apexcharts-grid-borders {
    line {
        stroke: var(--#{$prefix}light);
    }
}

// column dynamic loaded chart

#dynamicloadedchart-wrap {
    margin: 0px auto;
    max-width: 800px;
    position: relative;
}

.chart-box {
    padding-left: 0;
}

:is(#chart-year, #chart-quarter) {
    width: 96%;
    max-width: 48%;
    box-shadow: none;
    padding-left: 0;
    padding-top: 20px;
    background: var(--#{$prefix}secondary-bg);
    border: 1px solid var(--#{$prefix}border-color);
}

#chart-year {
    float: left;
    position: relative;
    transition: 1s ease transform;
    z-index: 3;

    &.chart-quarter-activated {
        transform: translateX(0);
        transition: 1s ease transform;
    }
}

#chart-quarter {
    float: left;
    position: relative;
    z-index: -2;
    transition: 1s ease transform;

    &.active {
        transition: 1.1s ease-in-out transform;
        transform: translateX(0);
        z-index: 1;
    }
}

@media screen and (min-width: 480px) {
    #chart-year {
        transform: translateX(50%);
    }

    #chart-quarter {
        transform: translateX(-50%);
    }
}